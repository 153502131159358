import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Check } from "../../../components/icons"
import * as styles from "./index.module.scss"

const PrefferedPartners = ({
  data: {
    preferredPartnerTitle,
    preferredPartnerImage,
    preferredPartnerBenefits,
  },
}) => {
  const img = getImage(preferredPartnerImage)
  return (
    <div className="px-4 md:px-8 xl:px-0 py-10 md:py-20">
      <div className="max-w-5xl mx-auto flex flex-wrap md:flex-nowrap gap-x-16 gap-y-6 items-center">
        <div className="md:w-3/12 w-full flex justify-center">
          <GatsbyImage
            image={img}
            loading="lazy"
            alt={preferredPartnerImage.title}
          />
        </div>
        <div className="md:w-9/12 w-full">
          <h3 className="mb-4 text-center md:text-left">
            {preferredPartnerTitle}
          </h3>
          <ul className="flex flex-col gap-y-2">
            {preferredPartnerBenefits &&
              preferredPartnerBenefits.length > 0 &&
              preferredPartnerBenefits.map(item => (
                <li
                  className={`flex gap-x-2 items-center ${styles.checkListItem}`}
                >
                  <span className={`${styles.check}`}>
                    <Check color="#fff" size={8} />
                  </span>
                  {item}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PrefferedPartners
