import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Check } from "../../../components/icons"

import * as styles from "./index.module.scss"

const ShowcaseFeature = ({ data: { title, image, benefits }, inverse }) => {
  const img = getImage(image)
  return (
    <div
      className={`px-4 md:px-8 xl:px-0 ${
        inverse && styles.showcaseFeatureContainer
      } py-8 lg:py-20`}
    >
      <div className="max-w-6xl mx-auto flex flex-wrap md:flex-nowrap lg:gap-x-14 md:gap-x-8 gap-y-6 items-center">
        <div className={`${inverse ? "lg:order-1" : ""} md:w-2/5 w-full`}>
          {/* <img src={image} alt="" className="w-6/12 md:w-full mx-auto" /> */}
          <GatsbyImage loading="lazy" image={img} alt={image.title} />
        </div>
        <div className="md:w-3/5 w-full">
          <h3 className="mb-6 md:mb-4">{title}</h3>
          <ul className="flex flex-col">
            {benefits &&
              benefits.length > 0 &&
              benefits.map(item => (
                <li className={`flex my-2 items-start ${styles.checkListItem}`}>
                  <span className={`${styles.check} mr-4 md:mr-2`}>
                    <Check color="#fff" size={8} />
                  </span>
                  {item}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ShowcaseFeature
