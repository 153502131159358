import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from "./index.module.scss"

const Benefits = ({ data }) => {
  return (
    <div className="pt-16">
      <div className="px-4 lg:px-8 xl:px-0 max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        {data &&
          data.length > 0 &&
          data.map(({ title, image }) => {
            const img = getImage(image)
            return (
              <div
                className={`p-6 flex items-center gap-4 ${styles.benefitContainer}`}
              >
                <div className="w-2/5">
                  <GatsbyImage loading="lazy" image={img} alt={image.title} />
                </div>
                <div className="w-3/5">
                  <h2>{title}</h2>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Benefits
