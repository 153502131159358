import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Benefits from "../containers/Quotes/Benefits"
import PrefferedPartners from "../containers/Quotes/PrefferedPartners"
import ShowcaseFeature from "../containers/Quotes/ShowcaseFeature"
import CaseStudies from "../components/caseStudies"

const Quotes = ({ data }) => {
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    featuresList,
    preferredPartnerTitle,
    preferredPartnerImage,
    preferredPartnerBenefits,
    showcaseList,
    caseStudiesTitle,
    caseStudiesList,
  } = data.quote

  const prefferedPartner = {
    preferredPartnerTitle,
    preferredPartnerImage,
    preferredPartnerBenefits,
  }

  const caseStudiesData = {
    caseStudiesTitle,
    caseStudiesList,
  }

  return (
    <Layout>
      <Seo title={ogTitle || "Quotes"} description={ogDescription} />
      <Banner title={bannerTitle} slogan={bannerSubtitle} image={bannerImage} />
      <Benefits data={featuresList} />
      <PrefferedPartners data={prefferedPartner} />
      {showcaseList &&
        showcaseList?.length > 0 &&
        showcaseList.map((feat, idx) => {
          const inverse = idx % 2 === 0
          return <ShowcaseFeature data={feat} inverse={inverse} />
        })}
      <CaseStudies data={caseStudiesData} padBot />
    </Layout>
  )
}

export const query = graphql`
  query QuotesQuery {
    quote: contentfulQuotesPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerImage {
        title
        gatsbyImageData(width: 400, quality: 100, placeholder: BLURRED)
      }
      featuresList {
        title
        image {
          gatsbyImageData(width: 180, quality: 100, placeholder: BLURRED)
        }
      }
      preferredPartnerTitle
      preferredPartnerImage {
        gatsbyImageData(width: 245, quality: 100, placeholder: BLURRED)
      }
      preferredPartnerBenefits
      showcaseList {
        title
        image {
          gatsbyImageData(width: 400, quality: 100, placeholder: BLURRED)
        }
        benefits
      }
      caseStudiesTitle
      caseStudiesList {
        id
        link
        slogan
        logo {
          title
          file {
            url
          }
        }
        backgroundImage {
          title
          gatsbyImageData(width: 650, placeholder: BLURRED)
        }
      }
    }
  }
`

export default Quotes
